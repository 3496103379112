/** @format */

body {
  background: #f3f4fa;
  color: #777;
  font-family: Montserrat, Arial, sans-serif;
}

.body-bg {
  background: #f3f4fa !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 600;
}

body {
  /*background: linear-gradient(45deg,#3a3a60,#5f5f8e);
  min-height: 100vh;*/
}

#area-adwords {
  min-height: 421px !important;
  margin-right: -20px;
}

.content-area {
  max-width: 1280px;
  margin: 0 auto;
}

.box {
  background-color: #fff;
  padding: 25px 20px;
}

.shadow {
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
}
.sparkboxes .box {
  padding-top: 30px;
  padding-bottom: 30px;
  text-shadow: 0 1px 1px 1px #666;
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  position: relative;
}

.sparkboxes strong {
  position: relative;
  z-index: 3;
  top: -8px;
  color: #fff;
}

.sparkboxes .box1 {
  border-bottom: 3px solid #734cea;
}

.sparkboxes .box2 {
  border-bottom: 3px solid #34bfa3;
}

.sparkboxes .box3 {
  border-bottom: 3px solid #f4516c;
}

.sparkboxes .box4 {
  border-bottom: 3px solid #00c5dc;
}

.spinner-border {
  display: none;
}
